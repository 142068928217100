import "moment";
import moment from "moment";

var jQuery = require('jquery'),
    $ = jQuery,
    $form = $('#class-request-form'),
    Pikaday = require('pikaday'),
    picker;

$form.find('input[name=\'child_birthday[d]\']').keyup(e => {
    if (e.target.value.length == 2) {
        $form.find('input[name=\'child_birthday[m]\']').focus();
    }
});

$form.find('input[name=\'child_birthday[m]\']').keyup(e => {
    if (e.target.value.length == 2) {
        $form.find('input[name=\'child_birthday[y]\']').focus();
    }
});

$form.submit(e => {

    $form.find('.alert').remove();

    var birthdate = moment($form.find('input[name=\'child_birthday[y]\']').val() + '-' + $form.find('input[name=\'child_birthday[m]\']').val() + '-' + $form.find('input[name=\'child_birthday[d]\']').val());

    if (birthdate.isValid() == false) {
        $form.find('input[name=\'child_birthday[y]\']').closest('.row').before('<div class="alert alert-danger"><strong>Das eingegebene Geburtsdatum ist ungültig.</strong><br>Bitte prüfen Sie das Datum und versuchen Sie es erneut.</div>');
        return false;
    }

    $form.find(':submit').text('Bitte warten...').prop('disabled', true);

    var data = $form.serializeArray(),
        submitURL = $form.attr('action');

    $.ajax({
        method: 'post',
        url: submitURL,
        data,
        dataType: 'json',
        success(booking) {
        
            var $success = $(`<div class="bg-secondary text-white p-3 p-md-5" style="display: none;">
                <h3 class="text-white">Vielen Dank, ${booking.gender == 'm' ? 'Herr' : 'Frau'} ${booking.lastname}!</h3>
                <p class="">Wir haben Ihre Anfrage erfolgreich erhalten. In den nächsten Minuten erhalten Sie eine Bestätigung Ihrer Anfrage per E-Mail.</p>
                <p class="">In den nächsten Tagen erhalten Sie von uns eine verbindliche Bestätigung zur Teilnahme an dem gewünschten Kurs, sowie Informationen zur Zahlung der Kursgebühr.</p>
                <p class="lead">Wir freuen uns auf Ihren Besuch!</p>
            </div>`);

            $form.slideUp('normal', e => {
                $form.replaceWith($success);
                $success.slideDown('normal');
            });

            

        },
        error(e) {
            alert('Beim Absenden Ihrer Anfrage ist leider ein technischer Fehler aufgetreten.');
            console.log(e);
        }
    });

    return false;

});