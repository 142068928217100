import jQuery from "jquery";
import slick from "slick-carousel";

jQuery('.upcoming-slider').slick({
    vertical: true,
    slidesToShow: 3,
    verticalSwiping: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: `<button type="button" class="slick-prev btn-block border-0 btn btn-primary">Früher <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 8l6 6H6z"/></svg></button>`,
    nextArrow: `<button type="button" class="slick-prev btn-block border-0 btn btn-primary">Später <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 16l-6-6h12z"/></svg></button>`
});