import "../sass/main.scss";

import jQuery from "jquery";
import "popper.js";
import "bootstrap";
import "slick-carousel";
import lax from 'lax.js';
import magnificPopup from 'magnific-popup';
import "./upcoming-slider";
import "./schedule-slider";

var $ = jQuery;

$("header.header-home .slider").slick({
    arrows: false,
    dots: true,
    fade: true,
    speed: 1000
});

$(".news-slider").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                dots: true,
                arrows: false,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                dots: true,
                arrows: false,
                slidesToScroll: 1,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false
            }
        }
    ]
});

$(".canvas-slider").slick({
    infinite: true,
    arrows: false,
    dots: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 2000
});

window.onload = function() {
	lax.setup({
        breakpoints: {
            small: 0,
            large: 992
        }
    }) // init

	const updateLax = () => {
		lax.update(window.scrollY)
		window.requestAnimationFrame(updateLax)
	}

    window.requestAnimationFrame(updateLax)

}

$("*[data-toggle=offcanvas]").each(function() {

    var $toggle = $(this);
    var $target = $($toggle.data('target'));

    $toggle.click(function() {
        $("body").toggleClass('offcanvas-open');
    });

});

$(document).click(function(e) {

    if(!$(e.target).hasClass('navbar-toggler') && !$(e.target).parent().hasClass('navbar-toggler') && $(e.target).closest('.offcanvas-menu').length == 0) {
        $("body").removeClass('offcanvas-open');
    }

});

$(".offcanvas-menu a[data-toggle='dropdown']").click(function() {

    var $link = $(this),
        $menu = $link.next('.dropdown-menu'),
        $li = $link.parent();

    $li.toggleClass('open');

    return false;

});

import "./class-booking-form";
import "./class-request-form";

$.extend(true, $.magnificPopup.defaults, {
    tClose: 'Schließen (Esc)', // Alt text on close button
    tLoading: 'Lade...', // Text that is displayed during loading. Can contain %curr% and %total% keys
    gallery: {
        tPrev: 'Voheriges Bild', // Alt text on left arrow
        tNext: 'Nächstes Bild', // Alt text on right arrow
        tCounter: 'Bild %curr% von %total%' // Markup for "1 of 7" counter
    },
    image: {
        tError: '<a href="%url%">Das Bild</a> konnte nicht geladen werden.' // Error message when image could not be loaded
    },
    ajax: {
        tError: '<a href="%url%">Der Inhalt</a> konnte nicht geladen werden.' // Error message when ajax request failed
    }
});

$(".lightbox-gallery").each(function(e) { 

    $(this).magnificPopup({
		delegate: 'a',
		type: 'image',
		mainClass: 'mfp-with-zoom mfp-img-mobile',
		image: {
			verticalFit: true,
			titleSrc: function(item) {
				return item.el.attr('title');
			}
		},
		gallery: {
			enabled: true
		},
		zoom: {
			enabled: true,
			duration: 300, // don't foget to change the duration also in CSS
			opener: function(element) {
				return element.find('img');
			}
		}
	});

});

$("header video.video").each((e, video) => {
   video.play();
});