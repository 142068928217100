var jQuery = require('jquery'),
    $ = jQuery,
    $form = $('#class-booking-form'),
    availableSeats = parseInt($form.data('available'));

$form.find('select[name=adults], select[name=children]').change(e => {

    $form.find('.alert').remove();

    var totalPersons = parseInt($form.find('select[name=adults]').val()) + parseInt($form.find('select[name=children]').val());

    if (totalPersons > availableSeats) {
        $form.append('<div class="alert alert-danger mt-4">Ups, leider haben wir nur noch <strong>' + availableSeats + ' Plätze</strong> verfügbar, sie haben jedoch ' + totalPersons + ' Personen ausgewählt. Bitte reduzieren Sie Ihre Teilnehmeranzahl.</div>');
        $form.find(':submit').prop('disabled', true);
    } else {
        $form.find(':submit').prop('disabled', false);
    }

});

$form.submit(e => {

    if ($form.find('select[name=adults]').val() == 0 && $form.find('select[name=children]').val() == 0) {
        alert('Bitte wählen Sie die Anzahl der Personen aus, die Sie anmelden möchten.');
        return false;
    }

    $form.find(':submit').text('Bitte warten...').prop('disabled', true);

    var data = $form.serializeArray(),
        submitURL = $form.attr('action');

    $.ajax({
        method: 'post',
        url: submitURL,
        data,
        dataType: 'json',
        success(booking) {
        
            var $success = $(`<div class="bg-secondary text-white p-3 p-md-5" style="display: none;">
                <h3 class="text-white">Vielen Dank, ${booking.gender == 'm' ? 'Herr' : 'Frau'} ${booking.lastname}!</h3>
                <p class="">Wir haben Ihre Buchung erfolgreich eingetragen. In den nächsten Minuten erhalten Sie eine Buchungsbestätigung per E-Mail.</p>
                <p class="lead">Wir freuen uns auf Ihren Besuch!</p>
            </div>`);

            $form.slideUp('normal', e => {
                $form.replaceWith($success);
                $success.slideDown('normal');
            });

            

        },
        error(e) {
            alert('Beim Absenden Ihrer Buchung ist leider ein technischer Fehler aufgetreten.');
            console.log(e);
        }
    });

    return false;

});