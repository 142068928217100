import jQuery from "jquery";

jQuery('.class-schedule-slider').slick({
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 7,
    adaptiveHeight: true,
    prevArrow: jQuery('.schedule-prev'),
    nextArrow: jQuery('.schedule-next'),
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
});

console.log(jQuery('.class-schedule-slider .name > a'));

jQuery('.class-schedule-slider .name > a').each(function() {

    jQuery(this).popover({
        container: 'body',
        html: true,
        placement: 'auto',
        trigger: 'hover',
        template: '<div class="popover popover-class" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });

});

// var $schedule = $("#schedule");

//         $schedule.slick({
            
//         });

//         $schedule.find('.class > .name > a').each(function() {

//             $(this).popover({
// 				container: 'body',
// 				html: true,
// 				placement: 'auto',
// 				trigger: 'hover',
// 				template: '<div class="popover popover-class-description" role="tooltip" style="max-width: 350px;"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
// 			});

//         });